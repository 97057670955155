const countries = [
  {
    country: "afghanistan",
    alpha2code: "af",

    latitude: 33,
    longitude: 65,
  },
  {
    country: "albania",
    alpha2code: "al",

    latitude: 41,
    longitude: 20,
  },
  {
    country: "algeria",
    alpha2code: "dz",

    latitude: 28,
    longitude: 3,
  },
  {
    country: "american samoa",
    alpha2code: "as",

    latitude: -14.3333,
    longitude: -170,
  },
  {
    country: "andorra",
    alpha2code: "ad",

    latitude: 42.5,
    longitude: 1.6,
  },
  {
    country: "angola",
    alpha2code: "ao",

    latitude: -12.5,
    longitude: 18.5,
  },
  {
    country: "anguilla",
    alpha2code: "ai",

    latitude: 18.25,
    longitude: -63.1667,
  },
  {
    country: "antarctica",
    alpha2code: "aq",

    latitude: -90,
    longitude: 0,
  },
  {
    country: "antigua and barbuda",
    alpha2code: "ag",

    latitude: 17.05,
    longitude: -61.8,
  },
  {
    country: "argentina",
    alpha2code: "ar",

    latitude: -34,
    longitude: -64,
  },
  {
    country: "armenia",
    alpha2code: "am",

    latitude: 40,
    longitude: 45,
  },
  {
    country: "aruba",
    alpha2code: "aw",

    latitude: 12.5,
    longitude: -69.9667,
  },
  {
    country: "australia",
    alpha2code: "au",

    latitude: -27,
    longitude: 133,
  },
  {
    country: "austria",
    alpha2code: "at",

    latitude: 47.3333,
    longitude: 13.3333,
  },
  {
    country: "azerbaijan",
    alpha2code: "az",

    latitude: 40.5,
    longitude: 47.5,
  },
  {
    country: "bahamas",
    alpha2code: "bs",

    latitude: 24.25,
    longitude: -76,
  },
  {
    country: "bahrain",
    alpha2code: "bh",

    latitude: 26,
    longitude: 50.55,
  },
  {
    country: "bangladesh",
    alpha2code: "bd",

    latitude: 24,
    longitude: 90,
  },
  {
    country: "barbados",
    alpha2code: "bb",

    latitude: 13.1667,
    longitude: -59.5333,
  },
  {
    country: "belarus",
    alpha2code: "by",

    latitude: 53,
    longitude: 28,
  },
  {
    country: "belgium",
    alpha2code: "be",

    latitude: 50.8333,
    longitude: 4,
  },
  {
    country: "belize",
    alpha2code: "bz",

    latitude: 17.25,
    longitude: -88.75,
  },
  {
    country: "benin",
    alpha2code: "bj",

    latitude: 9.5,
    longitude: 2.25,
  },
  {
    country: "bermuda",
    alpha2code: "bm",

    latitude: 32.3333,
    longitude: -64.75,
  },
  {
    country: "bhutan",
    alpha2code: "bt",

    latitude: 27.5,
    longitude: 90.5,
  },
  {
    country: "bolivia, plurinational state of",
    alpha2code: "bo",

    latitude: -17,
    longitude: -65,
  },
  {
    country: "bolivia",
    alpha2code: "bo",

    latitude: -17,
    longitude: -65,
  },
  {
    country: "bosnia and herzegovina",
    alpha2code: "ba",

    latitude: 44,
    longitude: 18,
  },
  {
    country: "botswana",
    alpha2code: "bw",

    latitude: -22,
    longitude: 24,
  },
  {
    country: "bouvet island",
    alpha2code: "bv",

    latitude: -54.4333,
    longitude: 3.4,
  },
  {
    country: "brazil",
    alpha2code: "br",

    latitude: -10,
    longitude: -55,
  },
  {
    country: "british indian ocean territory",
    alpha2code: "io",

    latitude: -6,
    longitude: 71.5,
  },
  {
    country: "brunei darussalam",
    alpha2code: "bn",

    latitude: 4.5,
    longitude: 114.6667,
  },
  {
    country: "brunei",
    alpha2code: "bn",

    latitude: 4.5,
    longitude: 114.6667,
  },
  {
    country: "bulgaria",
    alpha2code: "bg",

    latitude: 43,
    longitude: 25,
  },
  {
    country: "burkina faso",
    alpha2code: "bf",

    latitude: 13,
    longitude: -2,
  },
  {
    country: "burundi",
    alpha2code: "bi",

    latitude: -3.5,
    longitude: 30,
  },
  {
    country: "cambodia",
    alpha2code: "kh",

    latitude: 13,
    longitude: 105,
  },
  {
    country: "cameroon",
    alpha2code: "cm",

    latitude: 6,
    longitude: 12,
  },
  {
    country: "canada",
    alpha2code: "ca",

    latitude: 60,
    longitude: -95,
  },
  {
    country: "cape verde",
    alpha2code: "cv",

    latitude: 16,
    longitude: -24,
  },
  {
    country: "cayman islands",
    alpha2code: "ky",

    latitude: 19.5,
    longitude: -80.5,
  },
  {
    country: "central african republic",
    alpha2code: "cf",

    latitude: 7,
    longitude: 21,
  },
  {
    country: "chad",
    alpha2code: "td",

    latitude: 15,
    longitude: 19,
  },
  {
    country: "chile",
    alpha2code: "cl",

    latitude: -30,
    longitude: -71,
  },
  {
    country: "china",
    alpha2code: "cn",

    latitude: 35,
    longitude: 105,
  },
  {
    country: "christmas island",
    alpha2code: "cx",

    latitude: -10.5,
    longitude: 105.6667,
  },
  {
    country: "cocos (keeling) islands",
    alpha2code: "cc",

    latitude: -12.5,
    longitude: 96.8333,
  },
  {
    country: "colombia",
    alpha2code: "co",

    latitude: 4,
    longitude: -72,
  },
  {
    country: "comoros",
    alpha2code: "km",

    latitude: -12.1667,
    longitude: 44.25,
  },
  {
    country: "congo",
    alpha2code: "cg",

    latitude: -1,
    longitude: 15,
  },
  {
    country: "congo, the democratic republic of the",
    alpha2code: "cd",

    latitude: 0,
    longitude: 25,
  },
  {
    country: "cook islands",
    alpha2code: "ck",

    latitude: -21.2333,
    longitude: -159.7667,
  },
  {
    country: "costa rica",
    alpha2code: "cr",

    latitude: 10,
    longitude: -84,
  },
  {
    country: "côte d'ivoire",
    alpha2code: "ci",

    latitude: 8,
    longitude: -5,
  },
  {
    country: "ivory coast",
    alpha2code: "ci",

    latitude: 8,
    longitude: -5,
  },
  {
    country: "croatia",
    alpha2code: "hr",

    latitude: 45.1667,
    longitude: 15.5,
  },
  {
    country: "cuba",
    alpha2code: "cu",

    latitude: 21.5,
    longitude: -80,
  },
  {
    country: "cyprus",
    alpha2code: "cy",

    latitude: 35,
    longitude: 33,
  },
  {
    country: "czech republic",
    alpha2code: "cz",

    latitude: 49.75,
    longitude: 15.5,
  },
  {
    country: "denmark",
    alpha2code: "dk",

    latitude: 56,
    longitude: 10,
  },
  {
    country: "djibouti",
    alpha2code: "dj",

    latitude: 11.5,
    longitude: 43,
  },
  {
    country: "dominica",
    alpha2code: "dm",

    latitude: 15.4167,
    longitude: -61.3333,
  },
  {
    country: "dominican republic",
    alpha2code: "do",

    latitude: 19,
    longitude: -70.6667,
  },
  {
    country: "ecuador",
    alpha2code: "ec",

    latitude: -2,
    longitude: -77.5,
  },
  {
    country: "egypt",
    alpha2code: "eg",

    latitude: 27,
    longitude: 30,
  },
  {
    country: "el salvador",
    alpha2code: "sv",

    latitude: 13.8333,
    longitude: -88.9167,
  },
  {
    country: "equatorial guinea",
    alpha2code: "gq",

    latitude: 2,
    longitude: 10,
  },
  {
    country: "eritrea",
    alpha2code: "er",

    latitude: 15,
    longitude: 39,
  },
  {
    country: "estonia",
    alpha2code: "ee",

    latitude: 59,
    longitude: 26,
  },
  {
    country: "ethiopia",
    alpha2code: "et",

    latitude: 8,
    longitude: 38,
  },
  {
    country: "falkland islands (malvinas)",
    alpha2code: "fk",

    latitude: -51.75,
    longitude: -59,
  },
  {
    country: "faroe islands",
    alpha2code: "fo",

    latitude: 62,
    longitude: -7,
  },
  {
    country: "fiji",
    alpha2code: "fj",

    latitude: -18,
    longitude: 175,
  },
  {
    country: "finland",
    alpha2code: "fi",

    latitude: 64,
    longitude: 26,
  },
  {
    country: "france",
    alpha2code: "fr",

    latitude: 46,
    longitude: 2,
  },
  {
    country: "french guiana",
    alpha2code: "gf",

    latitude: 4,
    longitude: -53,
  },
  {
    country: "french polynesia",
    alpha2code: "pf",

    latitude: -15,
    longitude: -140,
  },
  {
    country: "french southern territories",
    alpha2code: "tf",

    latitude: -43,
    longitude: 67,
  },
  {
    country: "gabon",
    alpha2code: "ga",

    latitude: -1,
    longitude: 11.75,
  },
  {
    country: "gambia",
    alpha2code: "gm",

    latitude: 13.4667,
    longitude: -16.5667,
  },
  {
    country: "georgia",
    alpha2code: "ge",

    latitude: 42,
    longitude: 43.5,
  },
  {
    country: "germany",
    alpha2code: "de",

    latitude: 51,
    longitude: 9,
  },
  {
    country: "ghana",
    alpha2code: "gh",

    latitude: 8,
    longitude: -2,
  },
  {
    country: "gibraltar",
    alpha2code: "gi",

    latitude: 36.1833,
    longitude: -5.3667,
  },
  {
    country: "greece",
    alpha2code: "gr",

    latitude: 39,
    longitude: 22,
  },
  {
    country: "greenland",
    alpha2code: "gl",

    latitude: 72,
    longitude: -40,
  },
  {
    country: "grenada",
    alpha2code: "gd",

    latitude: 12.1167,
    longitude: -61.6667,
  },
  {
    country: "guadeloupe",
    alpha2code: "gp",

    latitude: 16.25,
    longitude: -61.5833,
  },
  {
    country: "guam",
    alpha2code: "gu",

    latitude: 13.4667,
    longitude: 144.7833,
  },
  {
    country: "guatemala",
    alpha2code: "gt",

    latitude: 15.5,
    longitude: -90.25,
  },
  {
    country: "guernsey",
    alpha2code: "gg",

    latitude: 49.5,
    longitude: -2.56,
  },
  {
    country: "guinea",
    alpha2code: "gn",

    latitude: 11,
    longitude: -10,
  },
  {
    country: "guinea-bissau",
    alpha2code: "gw",

    latitude: 12,
    longitude: -15,
  },
  {
    country: "guyana",
    alpha2code: "gy",

    latitude: 5,
    longitude: -59,
  },
  {
    country: "haiti",
    alpha2code: "ht",

    latitude: 19,
    longitude: -72.4167,
  },
  {
    country: "heard island and mcdonald islands",
    alpha2code: "hm",

    latitude: -53.1,
    longitude: 72.5167,
  },
  {
    country: "holy see (vatican city state)",
    alpha2code: "va",

    latitude: 41.9,
    longitude: 12.45,
  },
  {
    country: "honduras",
    alpha2code: "hn",

    latitude: 15,
    longitude: -86.5,
  },
  {
    country: "hong kong",
    alpha2code: "hk",

    latitude: 22.25,
    longitude: 114.1667,
  },
  {
    country: "hungary",
    alpha2code: "hu",

    latitude: 47,
    longitude: 20,
  },
  {
    country: "iceland",
    alpha2code: "is",

    latitude: 65,
    longitude: -18,
  },
  {
    country: "india",
    alpha2code: "in",

    latitude: 20,
    longitude: 77,
  },
  {
    country: "indonesia",
    alpha2code: "id",

    latitude: -5,
    longitude: 120,
  },
  {
    country: "iran, islamic republic of",
    alpha2code: "ir",

    latitude: 32,
    longitude: 53,
  },
  {
    country: "iraq",
    alpha2code: "iq",

    latitude: 33,
    longitude: 44,
  },
  {
    country: "ireland",
    alpha2code: "ie",

    latitude: 53,
    longitude: -8,
  },
  {
    country: "isle of man",
    alpha2code: "im",

    latitude: 54.23,
    longitude: -4.55,
  },
  {
    country: "israel",
    alpha2code: "il",

    latitude: 31.5,
    longitude: 34.75,
  },
  {
    country: "italy",
    alpha2code: "it",

    latitude: 42.8333,
    longitude: 12.8333,
  },
  {
    country: "jamaica",
    alpha2code: "jm",

    latitude: 18.25,
    longitude: -77.5,
  },
  {
    country: "japan",
    alpha2code: "jp",

    latitude: 36,
    longitude: 138,
  },
  {
    country: "jersey",
    alpha2code: "je",

    latitude: 49.21,
    longitude: -2.13,
  },
  {
    country: "jordan",
    alpha2code: "jo",

    latitude: 31,
    longitude: 36,
  },
  {
    country: "kazakhstan",
    alpha2code: "kz",

    latitude: 48,
    longitude: 68,
  },
  {
    country: "kenya",
    alpha2code: "ke",

    latitude: 1,
    longitude: 38,
  },
  {
    country: "kiribati",
    alpha2code: "ki",

    latitude: 1.4167,
    longitude: 173,
  },
  {
    country: "korea, democratic people's republic of",
    alpha2code: "kp",

    latitude: 40,
    longitude: 127,
  },
  {
    country: "korea, republic of",
    alpha2code: "kr",

    latitude: 37,
    longitude: 127.5,
  },
  {
    country: "south korea",
    alpha2code: "kr",

    latitude: 37,
    longitude: 127.5,
  },
  {
    country: "kuwait",
    alpha2code: "kw",

    latitude: 29.3375,
    longitude: 47.6581,
  },
  {
    country: "kyrgyzstan",
    alpha2code: "kg",

    latitude: 41,
    longitude: 75,
  },
  {
    country: "lao people's democratic republic",
    alpha2code: "la",

    latitude: 18,
    longitude: 105,
  },
  {
    country: "latvia",
    alpha2code: "lv",

    latitude: 57,
    longitude: 25,
  },
  {
    country: "lebanon",
    alpha2code: "lb",

    latitude: 33.8333,
    longitude: 35.8333,
  },
  {
    country: "lesotho",
    alpha2code: "ls",

    latitude: -29.5,
    longitude: 28.5,
  },
  {
    country: "liberia",
    alpha2code: "lr",

    latitude: 6.5,
    longitude: -9.5,
  },
  {
    country: "libyan arab jamahiriya",
    alpha2code: "ly",

    latitude: 25,
    longitude: 17,
  },
  {
    country: "libya",
    alpha2code: "ly",

    latitude: 25,
    longitude: 17,
  },
  {
    country: "liechtenstein",
    alpha2code: "li",

    latitude: 47.1667,
    longitude: 9.5333,
  },
  {
    country: "lithuania",
    alpha2code: "lt",

    latitude: 56,
    longitude: 24,
  },
  {
    country: "luxembourg",
    alpha2code: "lu",

    latitude: 49.75,
    longitude: 6.1667,
  },
  {
    country: "macao",
    alpha2code: "mo",

    latitude: 22.1667,
    longitude: 113.55,
  },
  {
    country: "macedonia, the former yugoslav republic of",
    alpha2code: "mk",

    latitude: 41.8333,
    longitude: 22,
  },
  {
    country: "madagascar",
    alpha2code: "mg",

    latitude: -20,
    longitude: 47,
  },
  {
    country: "malawi",
    alpha2code: "mw",

    latitude: -13.5,
    longitude: 34,
  },
  {
    country: "malaysia",
    alpha2code: "my",

    latitude: 2.5,
    longitude: 112.5,
  },
  {
    country: "maldives",
    alpha2code: "mv",

    latitude: 3.25,
    longitude: 73,
  },
  {
    country: "mali",
    alpha2code: "ml",

    latitude: 17,
    longitude: -4,
  },
  {
    country: "malta",
    alpha2code: "mt",

    latitude: 35.8333,
    longitude: 14.5833,
  },
  {
    country: "marshall islands",
    alpha2code: "mh",

    latitude: 9,
    longitude: 168,
  },
  {
    country: "martinique",
    alpha2code: "mq",

    latitude: 14.6667,
    longitude: -61,
  },
  {
    country: "mauritania",
    alpha2code: "mr",

    latitude: 20,
    longitude: -12,
  },
  {
    country: "mauritius",
    alpha2code: "mu",

    latitude: -20.2833,
    longitude: 57.55,
  },
  {
    country: "mayotte",
    alpha2code: "yt",

    latitude: -12.8333,
    longitude: 45.1667,
  },
  {
    country: "mexico",
    alpha2code: "mx",

    latitude: 23,
    longitude: -102,
  },
  {
    country: "micronesia, federated states of",
    alpha2code: "fm",

    latitude: 6.9167,
    longitude: 158.25,
  },
  {
    country: "moldova, republic of",
    alpha2code: "md",

    latitude: 47,
    longitude: 29,
  },
  {
    country: "monaco",
    alpha2code: "mc",

    latitude: 43.7333,
    longitude: 7.4,
  },
  {
    country: "mongolia",
    alpha2code: "mn",

    latitude: 46,
    longitude: 105,
  },
  {
    country: "montenegro",
    alpha2code: "me",

    latitude: 42,
    longitude: 19,
  },
  {
    country: "montserrat",
    alpha2code: "ms",

    latitude: 16.75,
    longitude: -62.2,
  },
  {
    country: "morocco",
    alpha2code: "ma",

    latitude: 32,
    longitude: -5,
  },
  {
    country: "mozambique",
    alpha2code: "mz",

    latitude: -18.25,
    longitude: 35,
  },
  {
    country: "myanmar",
    alpha2code: "mm",

    latitude: 22,
    longitude: 98,
  },
  {
    country: "burma",
    alpha2code: "mm",

    latitude: 22,
    longitude: 98,
  },
  {
    country: "namibia",
    alpha2code: "na",

    latitude: -22,
    longitude: 17,
  },
  {
    country: "nauru",
    alpha2code: "nr",

    latitude: -0.5333,
    longitude: 166.9167,
  },
  {
    country: "nepal",
    alpha2code: "np",

    latitude: 28,
    longitude: 84,
  },
  {
    country: "netherlands",
    alpha2code: "nl",

    latitude: 52.5,
    longitude: 5.75,
  },
  {
    country: "netherlands antilles",
    alpha2code: "an",

    latitude: 12.25,
    longitude: -68.75,
  },
  {
    country: "new caledonia",
    alpha2code: "nc",

    latitude: -21.5,
    longitude: 165.5,
  },
  {
    country: "new zealand",
    alpha2code: "nz",

    latitude: -41,
    longitude: 174,
  },
  {
    country: "nicaragua",
    alpha2code: "ni",

    latitude: 13,
    longitude: -85,
  },
  {
    country: "niger",
    alpha2code: "ne",

    latitude: 16,
    longitude: 8,
  },
  {
    country: "nigeria",
    alpha2code: "ng",

    latitude: 10,
    longitude: 8,
  },
  {
    country: "niue",
    alpha2code: "nu",

    latitude: -19.0333,
    longitude: -169.8667,
  },
  {
    country: "norfolk island",
    alpha2code: "nf",

    latitude: -29.0333,
    longitude: 167.95,
  },
  {
    country: "northern mariana islands",
    alpha2code: "mp",

    latitude: 15.2,
    longitude: 145.75,
  },
  {
    country: "norway",
    alpha2code: "no",

    latitude: 62,
    longitude: 10,
  },
  {
    country: "oman",
    alpha2code: "om",

    latitude: 21,
    longitude: 57,
  },
  {
    country: "pakistan",
    alpha2code: "pk",

    latitude: 30,
    longitude: 70,
  },
  {
    country: "palau",
    alpha2code: "pw",

    latitude: 7.5,
    longitude: 134.5,
  },
  {
    country: "palestinian territory, occupied",
    alpha2code: "ps",

    latitude: 32,
    longitude: 35.25,
  },
  {
    country: "panama",
    alpha2code: "pa",

    latitude: 9,
    longitude: -80,
  },
  {
    country: "papua new guinea",
    alpha2code: "pg",

    latitude: -6,
    longitude: 147,
  },
  {
    country: "paraguay",
    alpha2code: "py",

    latitude: -23,
    longitude: -58,
  },
  {
    country: "peru",
    alpha2code: "pe",

    latitude: -10,
    longitude: -76,
  },
  {
    country: "philippines",
    alpha2code: "ph",

    latitude: 13,
    longitude: 122,
  },
  {
    country: "pitcairn",
    alpha2code: "pn",

    latitude: -24.7,
    longitude: -127.4,
  },
  {
    country: "poland",
    alpha2code: "pl",

    latitude: 52,
    longitude: 20,
  },
  {
    country: "portugal",
    alpha2code: "pt",

    latitude: 39.5,
    longitude: -8,
  },
  {
    country: "puerto rico",
    alpha2code: "pr",

    latitude: 18.25,
    longitude: -66.5,
  },
  {
    country: "qatar",
    alpha2code: "qa",

    latitude: 25.5,
    longitude: 51.25,
  },
  {
    country: "réunion",
    alpha2code: "re",

    latitude: -21.1,
    longitude: 55.6,
  },
  {
    country: "romania",
    alpha2code: "ro",

    latitude: 46,
    longitude: 25,
  },
  {
    country: "russian federation",
    alpha2code: "ru",

    latitude: 60,
    longitude: 100,
  },
  {
    country: "russia",
    alpha2code: "ru",

    latitude: 60,
    longitude: 100,
  },
  {
    country: "rwanda",
    alpha2code: "rw",

    latitude: -2,
    longitude: 30,
  },
  {
    country: "saint helena, ascension and tristan da cunha",
    alpha2code: "sh",

    latitude: -15.9333,
    longitude: -5.7,
  },
  {
    country: "saint kitts and nevis",
    alpha2code: "kn",

    latitude: 17.3333,
    longitude: -62.75,
  },
  {
    country: "saint lucia",
    alpha2code: "lc",

    latitude: 13.8833,
    longitude: -61.1333,
  },
  {
    country: "saint pierre and miquelon",
    alpha2code: "pm",

    latitude: 46.8333,
    longitude: -56.3333,
  },
  {
    country: "saint vincent and the grenadines",
    alpha2code: "vc",

    latitude: 13.25,
    longitude: -61.2,
  },
  {
    country: "saint vincent & the grenadines",
    alpha2code: "vc",

    latitude: 13.25,
    longitude: -61.2,
  },
  {
    country: "st. vincent and the grenadines",
    alpha2code: "vc",

    latitude: 13.25,
    longitude: -61.2,
  },
  {
    country: "samoa",
    alpha2code: "ws",

    latitude: -13.5833,
    longitude: -172.3333,
  },
  {
    country: "san marino",
    alpha2code: "sm",

    latitude: 43.7667,
    longitude: 12.4167,
  },
  {
    country: "sao tome and principe",
    alpha2code: "st",

    latitude: 1,
    longitude: 7,
  },
  {
    country: "saudi arabia",
    alpha2code: "sa",

    latitude: 25,
    longitude: 45,
  },
  {
    country: "senegal",
    alpha2code: "sn",

    latitude: 14,
    longitude: -14,
  },
  {
    country: "serbia",
    alpha2code: "rs",

    latitude: 44,
    longitude: 21,
  },
  {
    country: "seychelles",
    alpha2code: "sc",

    latitude: -4.5833,
    longitude: 55.6667,
  },
  {
    country: "sierra leone",
    alpha2code: "sl",

    latitude: 8.5,
    longitude: -11.5,
  },
  {
    country: "singapore",
    alpha2code: "sg",

    latitude: 1.3667,
    longitude: 103.8,
  },
  {
    country: "slovakia",
    alpha2code: "sk",

    latitude: 48.6667,
    longitude: 19.5,
  },
  {
    country: "slovenia",
    alpha2code: "si",

    latitude: 46,
    longitude: 15,
  },
  {
    country: "solomon islands",
    alpha2code: "sb",

    latitude: -8,
    longitude: 159,
  },
  {
    country: "somalia",
    alpha2code: "so",

    latitude: 10,
    longitude: 49,
  },
  {
    country: "south africa",
    alpha2code: "za",

    latitude: -29,
    longitude: 24,
  },
  {
    country: "south georgia and the south sandwich islands",
    alpha2code: "gs",

    latitude: -54.5,
    longitude: -37,
  },
  {
    country: "south sudan",
    alpha2code: "ss",

    latitude: 8,
    longitude: 30,
  },
  {
    country: "spain",
    alpha2code: "es",

    latitude: 40,
    longitude: -4,
  },
  {
    country: "sri lanka",
    alpha2code: "lk",

    latitude: 7,
    longitude: 81,
  },
  {
    country: "sudan",
    alpha2code: "sd",

    latitude: 15,
    longitude: 30,
  },
  {
    country: "suriname",
    alpha2code: "sr",

    latitude: 4,
    longitude: -56,
  },
  {
    country: "svalbard and jan mayen",
    alpha2code: "sj",

    latitude: 78,
    longitude: 20,
  },
  {
    country: "swaziland",
    alpha2code: "sz",

    latitude: -26.5,
    longitude: 31.5,
  },
  {
    country: "sweden",
    alpha2code: "se",

    latitude: 62,
    longitude: 15,
  },
  {
    country: "switzerland",
    alpha2code: "ch",

    latitude: 47,
    longitude: 8,
  },
  {
    country: "syrian arab republic",
    alpha2code: "sy",

    latitude: 35,
    longitude: 38,
  },
  {
    country: "taiwan, province of china",
    alpha2code: "tw",

    latitude: 23.5,
    longitude: 121,
  },
  {
    country: "taiwan",
    alpha2code: "tw",

    latitude: 23.5,
    longitude: 121,
  },
  {
    country: "tajikistan",
    alpha2code: "tj",

    latitude: 39,
    longitude: 71,
  },
  {
    country: "tanzania, united republic of",
    alpha2code: "tz",

    latitude: -6,
    longitude: 35,
  },
  {
    country: "thailand",
    alpha2code: "th",

    latitude: 15,
    longitude: 100,
  },
  {
    country: "timor-leste",
    alpha2code: "tl",

    latitude: -8.55,
    longitude: 125.5167,
  },
  {
    country: "togo",
    alpha2code: "tg",

    latitude: 8,
    longitude: 1.1667,
  },
  {
    country: "tokelau",
    alpha2code: "tk",

    latitude: -9,
    longitude: -172,
  },
  {
    country: "tonga",
    alpha2code: "to",

    latitude: -20,
    longitude: -175,
  },
  {
    country: "trinidad and tobago",
    alpha2code: "tt",

    latitude: 11,
    longitude: -61,
  },
  {
    country: "tunisia",
    alpha2code: "tn",

    latitude: 34,
    longitude: 9,
  },
  {
    country: "turkey",
    alpha2code: "tr",

    latitude: 39,
    longitude: 35,
  },
  {
    country: "turkmenistan",
    alpha2code: "tm",

    latitude: 40,
    longitude: 60,
  },
  {
    country: "turks and caicos islands",
    alpha2code: "tc",

    latitude: 21.75,
    longitude: -71.5833,
  },
  {
    country: "tuvalu",
    alpha2code: "tv",

    latitude: -8,
    longitude: 178,
  },
  {
    country: "uganda",
    alpha2code: "ug",

    latitude: 1,
    longitude: 32,
  },
  {
    country: "ukraine",
    alpha2code: "ua",

    latitude: 49,
    longitude: 32,
  },
  {
    country: "united arab emirates",
    alpha2code: "ae",

    latitude: 24,
    longitude: 54,
  },
  {
    country: "united kingdom",
    alpha2code: "gb",

    latitude: 54,
    longitude: -2,
  },
  {
    country: "united states",
    alpha2code: "us",

    latitude: 38,
    longitude: -97,
  },
  {
    country: "united states minor outlying islands",
    alpha2code: "um",

    latitude: 19.2833,
    longitude: 166.6,
  },
  {
    country: "uruguay",
    alpha2code: "uy",

    latitude: -33,
    longitude: -56,
  },
  {
    country: "uzbekistan",
    alpha2code: "uz",

    latitude: 41,
    longitude: 64,
  },
  {
    country: "vanuatu",
    alpha2code: "vu",

    latitude: -16,
    longitude: 167,
  },
  {
    country: "venezuela, bolivarian republic of",
    alpha2code: "ve",

    latitude: 8,
    longitude: -66,
  },
  {
    country: "venezuela",
    alpha2code: "ve",

    latitude: 8,
    longitude: -66,
  },
  {
    country: "viet nam",
    alpha2code: "vn",

    latitude: 16,
    longitude: 106,
  },
  {
    country: "vietnam",
    alpha2code: "vn",

    latitude: 16,
    longitude: 106,
  },
  {
    country: "virgin islands, british",
    alpha2code: "vg",

    latitude: 18.5,
    longitude: -64.5,
  },
  {
    country: "virgin islands, u.s.",
    alpha2code: "vi",

    latitude: 18.3333,
    longitude: -64.8333,
  },
  {
    country: "wallis and futuna",
    alpha2code: "wf",

    latitude: -13.3,
    longitude: -176.2,
  },
  {
    country: "western sahara",
    alpha2code: "eh",

    latitude: 24.5,
    longitude: -13,
  },
  {
    country: "yemen",
    alpha2code: "ye",

    latitude: 15,
    longitude: 48,
  },
  {
    country: "zambia",
    alpha2code: "zm",

    latitude: -15,
    longitude: 30,
  },
  {
    country: "zimbabwe",
    alpha2code: "zw",

    latitude: -20,
    longitude: 30,
  },
];

export default countries;
