import "./App.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import fetch from "node-fetch";
import countries from "./countries";
import React, { useState, useEffect } from "react";
import Loading from "react-fullscreen-loading";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const App = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://worldnews.lonely.codes/api/getNews")
      .then((res) => res.json())
      .then((data) => {
        setArticles(data["articles"]);
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Loading loading={true} background="#908F93" loaderColor="#EFEFEF" />
    );
  }

  return (
    <>
      <h1
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          marginTop: "25px",
        }}
      >
        WorldNews
      </h1>
      <MapContainer
        center={[46.948, 7.4474]}
        zoom={3}
        style={{ position: "absolute", height: "80%", width: "100%" }}
        tap={false}
      >
        <TileLayer
          attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
          url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
        />

        {countries.map((country) => {
          let article = null;
          for (const a of articles) {
            if (a.country === country.alpha2code) {
              article = a;
            }
          }
          return (
            article && (
              <Marker position={[country.latitude, country.longitude]}>
                <Popup>
                  <b>{article?.title}</b> <br />
                  {article?.preview} <br />
                  <img
                    width="100%"
                    height="100%"
                    src={article?.imageUrl}
                    alt="news"
                  />{" "}
                  <br />
                  <a
                    href={article?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                  </a>
                </Popup>
              </Marker>
            )
          );
        })}
      </MapContainer>
    </>
  );
};

export default App;
